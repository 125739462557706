<template>
  <div>
    <a-form :form="form"
            @submit="handleSubmit"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
            :colon="false">
      <a-row>
        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="当前项目"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="招标编号">
            <a-input v-decorator="[
                'bidCode',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="工程名称"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="标段名称"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-input v-decorator="[
                'bidName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="商务要求概述"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-textarea :auto-size="{ minRows: 5 }"
                        v-decorator="[
                'bidBusinessDesc'
              ]"
                        placeholder="(资格预审要求、资质要求、业绩、保证金、主要评标条款等)" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="技术要求概述"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-textarea :auto-size="{ minRows: 5 }"
                        v-decorator="[
                'bidTechDesc'
              ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <span slot="label"
                  class="">附件</span>
            <div class="link-list">
              <a-checkbox-group :value="selectedFileList"
                                @change="onSelectFile"
                                v-if="fileList.length !== 0">
                <div class="link"
                     v-for="item in fileList"
                     :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank"
                       download
                       :href="item.completePath">{{
                      item.name
                    }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color:#ccc;"
                   v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color:#ccc;line-height:1.5;"
                   v-else>
                《招标商务文件》<br>
                《招标技术文件》
              </div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control"
                     @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit"
                    type="primary"
                    v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import {
  add,
  edit,
  fetchDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
      }),
    },
  },
  components: {
    FileUpload,
  },
  data() {
    return {
      stage: "epc_purchase_bid_public",

      form: this.$form.createForm(this),
      id: "", //当前表单的id
      hid: "", //历史记录id
      isView: false,

      fileList: [],
      selectedFileList: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id, hid, isView } = query || {};

    this.id = id || "";
    this.hid = hid || "";

    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.id = res.id;
          this.form.setFieldsValue({
            bidCode: res.bidCode,
            bidName: res.bidName,
            bidBusinessDesc: res.bidBusinessDesc,
            bidTechDesc: res.bidTechDesc,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          console.log(values);

          if (!this.id) {
            add({
              ...values,
              pid: this.msg.pid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
            });
          } else {
            edit({
              ...values,
              pid: this.msg.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
