<template>
  <div>
    <a-form :form="form"
            @submit="handleSubmit"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
            :colon="false">
      <a-row>
        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="当前项目"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.designCode"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="招标编号">
            <a-input v-decorator="[
                'bidCode',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="工程名称"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="msg.projectName" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="标段名称"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-input v-decorator="[
                'bidName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]"></a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="开标日期"
                       class="date">
            <a-date-picker placeholder="请选择"
                           v-decorator="[
                'bidStartDate',
                { rules: [{ required: true, message: '请选择！' }] },
              ]"
                           style="width: 100%"
                           format="YYYY-MM-DD HH:mm:ss" />
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="截标日期"
                       class="date">
            <a-date-picker placeholder="请选择"
                           v-decorator="[
                'bidEndDate',
                { rules: [{ required: true, message: '请选择！' }] },
              ]"
                           style="width: 100%"
                           format="YYYY-MM-DD HH:mm:ss" />
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="数量及单位">
            <a-input placeholder="请输入"
                     v-decorator="[
                'countUnit',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="要求到货时间"
                       class="date">
            <a-date-picker placeholder="请选择"
                           v-decorator="[
                'prdArrivalDate',
                { rules: [{ required: true, message: '请选择！' }] },
              ]"
                           style="width: 100%"
                           format="YYYY-MM-DD HH:mm:ss" />
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="受邀公司"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <SupplierSeletor @change="(company) => (selectedCompany = company)"
                             :msg='selectedCompany'>
              <a-button block
                        style="text-align: left; min-height:32px; height:auto;">
                <div v-if="selectedCompany.length > 0"
                     class="wrap">
                  <span v-for="(item, index) in selectedCompany"
                        :key="index"
                        style="margin-right:5px;">
                    {{ `${item.company} ` }}
                  </span>
                </div>
                <div v-else
                     style="color: #1890ff;">请选择</div>
              </a-button>
            </SupplierSeletor>
          </a-form-item>
        </a-col>

        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="主要内容"
                       :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <a-textarea :auto-size="{ minRows: 5 }"
                        v-decorator="[
                'bidInvitedContent',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]" />
          </a-form-item>
        </a-col>
        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item :label-col="{ span: 4 }"
                       :wrapper-col="{ span: 18 }">
            <span slot="label"
                  class="">附件</span>
            <div class="link-list">
              <a-checkbox-group :value="selectedFileList"
                                @change="onSelectFile"
                                v-if="fileList.length !== 0">
                <div class="link"
                     v-for="item in fileList"
                     :key="item.id">
                  <a-checkbox :value="item.id">
                    <a target="_blank"
                       download
                       :href="item.completePath">{{
                      item.name
                    }}</a>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
              <div style="color:#ccc;"
                   v-else-if="fileList.length === 0 && id">
                无
              </div>
              <div style="color:#ccc;"
                   v-else>
                《招标邀请函》
              </div>

              <div class="control-bar">
                <FileUpload @uploaded="uploaded">
                  <div class="control">
                    <a-icon type="upload" />
                  </div>
                </FileUpload>

                <div class="control"
                     @click="deleteFile">
                  <a-icon type="delete" />
                </div>
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button htmlType="submit"
                    type="primary"
                    v-if="!isView">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import SupplierSeletor from "@/components/supplier-selector";
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";

import {
  add,
  edit,
  fetchDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/purchase";
import { mapGetters } from "vuex";

import moment from "moment";

export default {
  props: {
    msg: {
      type: Object,
      default: () => ({
        pid: "",
        designCode: "",
        projectName: "",
      }),
    },
  },
  components: {
    SupplierSeletor,
    FileUpload,
  },
  data() {
    return {
      stage: "epc_purchase_bid_invited",

      id: "", //当前表单的id
      hid: "", //历史记录id
      isView: false,
      form: this.$form.createForm(this),
      selectedCompany: [],

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { id, hid, isView } = query || {};

    this.id = id || "";
    this.hid = hid || "";

    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      fetchDetail({ id: this.id })
        .then((res) => {
          if (!res.id) return;

          this.id = res.id;
          this.selectedCompany = res.invitedCompanyList.map((item) => {
            return {
              id: item.id,
              company: item.name,
            };
          });
          this.form.setFieldsValue({
            bidCode: res.bidCode,
            bidName: res.bidName,
            bidStartDate: res.bidStartDate,
            bidEndDate: res.bidEndDate,
            countUnit: res.countUnit,
            prdArrivalDate: res.prdArrivalDate,
            bidInvitedContent: res.bidInvitedContent,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },

    exportForm() {
      exportFile({
        id: this.id,
        type: this.stage,
      }).then((blob) => {
        saveAs(blob, `${this.msg.projectName}_${this.stageName}.docx`);
      });
    },
    download() {
      downloadTemplate(this.stage).then((blob) => {
        saveAs(blob, `${this.stageName}模版.docx`);
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          let invitedCompanyList = this.selectedCompany.map((item) => {
            return {
              id: item.id,
              name: item.company,
            };
          });
          console.log(values, invitedCompanyList);

          if (!this.id) {
            add({
              ...values,
              bidEndDate: moment(values.bidEndDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              bidStartDate: moment(values.bidStartDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              prdArrivalDate: moment(values.prdArrivalDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              pid: this.msg.pid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
              invitedCompanyList: invitedCompanyList,
              type: this.stage,
            }).then(() => {
              this.form.resetFields();
              this.fileList = [];
              this.selectedCompany = [];
            });
          } else {
            edit({
              ...values,
              pid: this.msg.pid,
              id: this.id,
              historyId: this.hid,
              projectName: this.msg.projectName,
              designCode: this.msg.designCode,
              attachments: this.fileList,
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
